<template>
  <div class="sidebar-right-container tw-w-full tw-items-center">
    <template v-if="currentItem">
      <zem-card>
        <div>
          <zem-card-title>Детали лога</zem-card-title>
          <div v-if="!closeAcc([3])" class="block-id">
            <span class="block-id__label second"></span>
            ID {{ currentItem['id'] }}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-x-3 cursor-pointer">
          <img :src="require('@/assets/icons/close.svg')" alt="" class="" @click="closeRightSidebar" />
        </div>
      </zem-card>
    </template>

    <zem-collapse is-opened-default title="Общее">
      <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
        <div class="tw-text-[#9ba6b2] tw-text-xs">Заказ</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer">12385-R3</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Тип</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">Замер</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Описание</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          Это текстовое поле, в которое можно вписать любой комментарий из офиса или другую информацию от создателя
          мероприятия
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Статус</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">Началось</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Подтверждение</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">Требует</div>
      </div>
    </zem-collapse>

    <div v-show="isLoadingData" class="sidebar-right-preloader">
      <ZemPreloader :color="'dark'" :size="20" />
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import {closeAcc, formattedDateTime} from '@/assets/scripts/scripts'
import {MapIcon} from 'vue-feather-icons'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import LogsService from '@/services/logs.service'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    MapIcon,
    JsonViewer,
  },
  data() {
    return {
      isLoadingData: false,
      currentItem: null,
    }
  },
  mounted() {
    const {id} = this.$route.params

    if (id) {
      this.getData(id)
    }
  },
  methods: {
    closeAcc,
    formattedDateTime,
    closeRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$router.push({name: 'events'})
    },
    getData(id) {
      this.isLoadingData = true
      LogsService.getLog(id)
        .then(r => {
          this.currentItem = r.data.data
          this.isLoadingData = false
          this.$store.commit('events/itemsForDelete', [id])
        })
        .catch(() => {
          this.$router.push({name: 'events'})
        })
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.sidebar-right-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      //img:not(:first-child) {
      //  margin-left: 8px;
      //}

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }

  > .sidebar-right-preloader {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  position: relative;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
