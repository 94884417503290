import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'

class EventsService {
  async getEvents() {
    store.commit('other/onChangeLoadingItems', true)

    const {paginationPage, orderBy, sortedBy} = store.state.events

    const params = {
      page: paginationPage,
      includes: 'status,type,order,createdBy,updatedBy,responsibleBy',
    }

    if (orderBy !== null) params['orderBy'] = orderBy
    if (sortedBy !== null) params['sortedBy'] = sortedBy

    return await $api
      .get('events', {
        params,
        headers: authHeader(),
      })
      .then(async r => {
        await store.commit('events/getAllElements', r.data)
        await store.commit('other/onChangeLoadingItems', false)
      })
  }

  getTypes() {
    return $api.get('event-types?to=list', {headers: authHeader()})
  }

  getStatuses() {
    return $api.get('event-statuses?to=list', {headers: authHeader()})
  }
}

export default new EventsService()
