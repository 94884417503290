<template>
  <section class="tw-w-full tw-px-5 tw-py-5 tw-h-dvh tw-overflow-hidden">
    <div class="tw-flex tw-gap-x-4 tw-justify-between">
      <div>
        <div class="tw-flex tw-items-center tw-gap-x-11">
          <h1 class="tw-text-lg tw-font-semibold tw-leading-[18px] tw-text-[#3E3E3E]">График занятости</h1>
          <span class="tw-text-xs tw-font-semibold">Август - Сентябрь 2024</span>
          <div class="tw-flex tw-items-center">
            <div class="tw-flex tw-items-center tw-justify-between tw-gap-x-4 tw-w-[135px]">
              <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]" @click="dayOffset--">
                <i-chevron-left class="tw-w-3 tw-h-3 tw-text-white" stroke-width="3" />
              </button>
              <span class="tw-text-xs tw-text-[#0DB2B2] tw-font-semibold">{{ currentDay }}</span>
              <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]" @click="dayOffset++">
                <i-chevron-right class="tw-w-3 tw-h-3 tw-text-white" stroke-width="3" />
              </button>
            </div>
            <button
              v-if="dayOffset !== 0"
              class="tw-text-xs tw-font-semibold tw-ml-3 tw-transition hover:tw-text-[#0DB2B2]"
              @click="dayOffset = 0"
            >
              Сегодня
            </button>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
        <slot name="head-buttons"></slot>
      </div>
    </div>
    <div class="tw-w-full tw-mt-5">
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <div class="tw-relative tw-w-[220px]">
          <ZemInput
            v-model="searchQuery"
            class="m-0 tw-mt-0"
            placeholder="Поиск по ID, артикулу заказа"
            type="text"
          ></ZemInput>
          <img
            :src="require('@/assets/icons/search.svg')"
            alt=""
            class="tw-absolute tw-right-[9px] tw-top-2 tw-w-2.5"
          />
        </div>
        <p class="tw-text-[#9BA6B2] tw-text-[10px]">
          {{ $t('message.Found') }} 1 {{ $t('message.of') }}
          5
        </p>
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-ml-7">
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 0}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 0"
          >
            День
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 1}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 1"
          >
            Неделя
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 2}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 2"
          >
            2 недели
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 3}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 3"
          >
            Месяц
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 4}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 4"
          >
            Квартал
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 5}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer"
            @click="tab = 5"
          >
            Год
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex-grow tw-overflow-y-auto tw-mt-5">
      <table class="events-table">
        <thead class="tw-sticky tw-top-[-1px] tw-z-[2]">
          <tr class="tw-sticky tw-left-[-1px] tw-z-[2]">
            <th class="tw-w-[300px] tw-min-w-[300px]">
              <div>
                <div>
                  <i-arrow-dropdown class="tw-w-1.5" fill="#9BA6B2" />
                  Участники
                </div>
              </div>
            </th>
            <th
              v-for="(calendarDay, index) in calendarDays"
              :key="index"
              :class="
                calendarDay.isCurrent && tab !== 0 ? 'tw-border-x-2 -tw-border-offset-4 tw-border-x-[#0DB2B2]' : ''
              "
            >
              <div>{{ calendarDay.title }} <span>пн</span></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(member, index) in members" :key="index" :class="{'events-is-collapse': !member.isCollapse}">
            <td
              class="tw-pl-4 tw-py-3 tw-align-top tw-text-sm tw-w-[300px] tw-min-w-[300px] tw-sticky tw-left-[-1px] tw-bg-white"
            >
              <div
                :class="{'tw-text-[#0DB2B2] tw-font-semibold': member.isCollapse}"
                class="tw-flex tw-items-center tw-gap-x-1 tw-cursor-pointer"
                @click="member.isCollapse = !member.isCollapse"
              >
                <i-arrow-dropdown
                  :class="!member.isCollapse ? '-tw-rotate-90' : 'tw-fill-[#0DB2B2]'"
                  class="tw-w-1.5"
                  fill="#9BA6B2"
                />
                {{ member.name }}
              </div>
              <p class="tw-text-[#9BA6B2] tw-pl-2.5">{{ member.position }}</p>
            </td>
            <td
              v-for="(day, indexDay) in member.days"
              :key="indexDay"
              :class="{'tw-border-x-2 -tw-border-offset-4 tw-border-x-[#0DB2B2]': day.isCurrent && tab !== 0}"
              class="tw-align-top"
            >
              <div class="events-container">
                <div
                  v-for="(event, indexEvent) in day.events"
                  :key="indexEvent"
                  :class="{'is-focus tw-delay-500': event.isFocus}"
                  class="event tw-transition"
                  @mouseleave="onUnFocusEvents"
                  @mouseover="onFocusEvents(event)"
                >
                  <span :class="colorStatus(event.status)" class="event-status"></span>
                  <div class="event-body">
                    <div class="event-type">
                      <span class="tw-max-w-[75px] tw-truncate">{{ event.type }}</span>
                      <span>{{ event.code }}</span>
                    </div>
                    <div class="event-time">
                      <span>{{ event.time }}</span>
                      <i-rain />
                    </div>
                    <div class="event-location">
                      <span>{{ event.location }}</span>
                      <span>{{ event.locationCode }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IChevronLeft from '@/components/icons/iChevronLeft.vue'
import IChevronRight from '@/components/icons/iChevronRight.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import IArrowDropdown from '@/components/icons/iArrowDropdown.vue'
import IRain from '@/components/icons/iRain.vue'
import dayjs from 'dayjs'

export default {
  components: {IRain, IArrowDropdown, ZemInput, IChevronRight, IChevronLeft},
  data() {
    return {
      searchQuery: '',
      tab: 1,
      calendarDays: [],
      dayOffset: 0,
      currentDay: 'Сегодня',
      members: [],
    }
  },
  watch: {
    async dayOffset() {
      await this.createdDays()
      const isNow = this.dayOffset === 0
      this.currentDay = isNow ? 'Сегодня' : dayjs().add(this.dayOffset, 'day').format('DD.MM')
      await this.generateMembers()
    },
    async tab() {
      await this.createdDays()
      const isNow = this.dayOffset === 0
      this.currentDay = isNow ? 'Сегодня' : dayjs().add(this.dayOffset, 'day').format('DD.MM')
      await this.generateMembers()
    },
  },
  async mounted() {
    await this.createdDays()
    await this.generateMembers()
  },
  methods: {
    createdDays() {
      this.calendarDays = []
      if (this.tab === 0) {
        for (let i = 0; i < 1; i++) {
          const date = dayjs().add(i + this.dayOffset, 'day')
          this.calendarDays.push({
            title: date.format('DD.MM'),
            date: date.format('DD.MM.YYYY'),
            isCurrent: dayjs().format('DD.MM.YYYY') === date.format('DD.MM.YYYY'),
          })
        }
      } else if (this.tab === 1) {
        for (let i = 0; i < 7; i++) {
          const date = dayjs()
            .subtract(2, 'day')
            .add(i + this.dayOffset, 'day')
          this.calendarDays.push({
            title: date.format('DD.MM'),
            date: date.format('DD.MM.YYYY'),
            isCurrent: dayjs().format('DD.MM.YYYY') === date.format('DD.MM.YYYY'),
          })
        }
      } else if (this.tab === 2) {
        for (let i = 0; i < 14; i++) {
          const date = dayjs()
            .subtract(2, 'day')
            .add(i + this.dayOffset, 'day')
          this.calendarDays.push({
            title: date.format('DD.MM'),
            date: date.format('DD.MM.YYYY'),
            isCurrent: dayjs().format('DD.MM.YYYY') === date.format('DD.MM.YYYY'),
          })
        }
      }
    },
    generateMembers() {
      this.members = [
        {
          name: 'Кузнецов Сергей',
          position: 'Менеджер',
          isCollapse: false,
          days: this.generateMockDays(), // Сгенерировать дни через функцию
        },
        {
          name: 'Попова Ксения',
          position: 'Техник',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Васильева Наталья',
          position: 'Электрик',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Морозов Дмитрий',
          position: 'Юрист',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Петрова Елена',
          position: 'Бухгалтер',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Волкова Анна',
          position: 'Специалист по закупкам',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Соловьёва Ирина',
          position: 'HR-менеджер',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Васильев Виктор',
          position: 'Аналитик',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Зайцева Юлия',
          position: 'Маркетолог',
          isCollapse: false,
          days: this.generateMockDays(),
        },
        {
          name: 'Сорокина Маргарита',
          position: 'Строитель',
          isCollapse: false,
          days: this.generateMockDays(),
        },
      ]
    },
    colorStatus(status) {
      return status ? `status-${status}` : ''
    },
    onFocusEvents(event) {
      this.members.forEach(member => {
        member.days.forEach(day => {
          day.events.forEach(ev => {
            if (ev.parent === event.parent) {
              ev.isFocus = true
            }
          })
        })
      })
    },
    onUnFocusEvents() {
      this.members.forEach(member => {
        member.days.forEach(day => {
          day.events.forEach(ev => {
            ev.isFocus = false
          })
        })
      })
    },
    generateMockDays() {
      // Эта функция генерирует случайные данные для дней
      let days = []
      for (const i in this.calendarDays) {
        let numberOfEvents = Math.floor(Math.random() * 6) // Случайное количество событий от 0 до 5
        let events = []
        let workTypes = [
          'Работы',
          'Обслуживание',
          'Планирование',
          'Инспекция',
          'Ремонт',
          'Модернизация',
          'Техническое обследование',
          'Санитарная обработка',
          'Озеленение',
          'Уборка территории',
          'Безопасность',
          'Энергоэффективность',
          'Автоматизация',
          'Дизайн интерьера',
          'Реставрация',
          'Консервация',
          'Реконструкция',
          'Логистика',
          'Эксплуатация',
          'Мониторинг состояния',
        ]
        for (let j = 0; j < numberOfEvents; j++) {
          events.push({
            isFocus: false,
            parent: Math.floor(Math.random() * 10),
            status: ['in_work', 'new', 'done', 'error'][Math.floor(Math.random() * 4)],
            type: workTypes[Math.floor(Math.random() * workTypes.length)],
            code: `55555-R${Math.floor(Math.random() * 100)}`,
            time: `${Math.floor(Math.random() * 24)}:00-${Math.floor(Math.random() * 24)}:00`,
            location: ['Симферопольское', 'Керченское', 'Бахчисарайское', 'Ялтинское'][Math.floor(Math.random() * 4)],
            locationCode: '150',
          })
        }
        days.push({
          date: this.calendarDays[i].title,
          events: events,
          isCurrent: this.calendarDays[i].isCurrent,
        })
      }
      return days
    },
  },
}
</script>

<style lang="scss" scoped>
.events-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  border: 1px solid #f0f0f0;

  thead {
    background-color: #f8fafb;
  }

  .events-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  td,
  th {
    border: 1px solid transparent;
  }

  tr:not(:first-child):not(:last-child) td,
  tr:not(:first-child):not(:last-child) th,
  tr td:not(:first-child):not(:last-child),
  tr th:not(:first-child):not(:last-child),
  tr:first-child td:not(:first-child):not(:last-child),
  tr:first-child th:not(:first-child):not(:last-child),
  tr:last-child td:not(:first-child):not(:last-child),
  tr:last-child th:not(:first-child):not(:last-child) {
    border-color: #f0f0f0;
  }

  th {
    color: #9ba6b2;
    padding: 2px 0;
    font-weight: 700;
    //border: 1px solid #f0f0f0;

    &:first-child {
      div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        div {
          height: 100%;
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
        }
      }
    }

    &:not(:first-child) div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  th,
  td {
    &:not(:first-child) {
      //width: 144px;
    }
  }

  td {
    color: #3e3e3e;
    padding: 2px;
    //border: 1px solid #f0f0f0;
  }

  .event {
    min-width: 170px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f5f8fa;
    //max-width: 140px;
    cursor: pointer;

    &.is-focus {
      background: #d5ebf4;
    }

    .event-status {
      display: block;
      height: 3px;
      width: 100%;
      background: #32c5ff;

      &.status-new {
        background: #32c5ff;
      }

      &.status-in_work {
        background: #ffc205;
      }

      &.status-done {
        background: #52c41a;
      }

      &.status-error {
        background: #ff4d4f;
      }
    }

    .event-body {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 2px 5px;
      font-size: 10px;
      color: #3e3e3e;

      .event-type {
        display: flex;
        justify-content: space-between;
        font-weight: 600;

        span:last-child {
          color: #9ba6b2;
        }
      }

      .event-time {
        display: flex;
        justify-content: space-between;

        span:last-child {
          color: #9ba6b2;
        }
      }

      .event-location {
        display: flex;
        justify-content: space-between;

        span:last-child {
          color: #9ba6b2;
        }
      }
    }
  }
}

.events-is-collapse {
  .event-body {
    height: 20px;
    overflow: hidden;
  }
}
</style>
