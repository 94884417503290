<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterEventsForm v-show="$store.state.sidebars.leftSidebar" ref="filter" />
    </SidebarLeft>

    <TableContainer
      v-show="!isChart"
      ref="table-container"
      :columns="tableData"
      :counter-filter="this.$store.state.events.searchCount"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :rows="$store.state.events.elements"
      :sorting-fields="sortingFields"
      name="logs"
      title="Мероприятия"
      @update-data="getElements"
      @on-selected="selectItem"
    >
      <template v-slot:head-buttons>
        <zem-link class="tw-gap-x-1.5" @click="isChart = true">
          <i-chart class="tw-w-5" />
          <span class="mobile-d-none">График</span>
        </zem-link>
        <ZemButton
          v-if="$can('edit', 'event-create')"
          :disabled="isLoadingItem"
          :loading="isLoadingItem"
          icon
          @click="onCreateItem"
        >
          <img v-if="!isLoadingItem" :src="require('@/assets/icons/plus.svg')" alt="" />
          <span class="mobile-d-none tw-ml-1.5">Мероприятие</span>
        </ZemButton>
      </template>
    </TableContainer>

    <EventsChart :class="!isChart ? 'tw-hidden' : 'tw-flex tw-flex-col'">
      <template v-slot:head-buttons>
        <zem-link class="tw-gap-x-1.5" @click="isChart = false">
          <i-table class="tw-w-5" />
          <span class="mobile-d-none">Таблица</span>
        </zem-link>
        <ZemButton v-if="$can('edit', 'event-create')" :disabled="isLoadingItem" :loading="isLoadingItem" icon>
          <img v-if="!isLoadingItem" :src="require('@/assets/icons/plus.svg')" alt="" />
          <span class="mobile-d-none tw-ml-1.5">Мероприятие</span>
        </ZemButton>
      </template>
    </EventsChart>

    <SidebarRight :class="{'sidebar-right--show tw-w-[500px] tw-min-w-[500px]': sidebars.rightSidebar}">
      <EventChange v-if="isNew" @close="isNew = false" />
      <EventDetails v-else ref="event-details" />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import EventDetails from '@/components/EventDetails.vue'
import FilterEventsForm from '@/components/FilterEventsForm.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import EventsChart from '@/components/EventsChart.vue'
import IChart from '@/components/icons/iChart.vue'
import ITable from '@/components/icons/iTable.vue'
import EventsService from '@/services/events.service'
import EventChange from '@/components/EventChange.vue'

export default {
  components: {
    EventChange,
    ITable,
    IChart,
    EventsChart,
    ZemLink,
    ZemPreloader,
    FilterEventsForm,
    EventDetails,
    ZemButton,
    SidebarLeft,
    SidebarRight,
    TableContainer,
  },

  data() {
    return {
      isNew: false,
      isChart: false,
      isLoadingItem: false,
      sortingFields: ['order_id', 'created_at', 'code', 'exception'],
      tableData: [
        {
          label: 'Заказ',
          field: 'order_id',
          sortField: 'order_id',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Ответственный',
          field: 'created_at',
          sortField: 'created_at',
          type: 'datetime',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Тип мероприятия',
          field: 'code',
          sortField: 'code',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Подтверждение',
          field: 'exception',
          sortField: 'exception',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Дата начала',
          field: 'exception',
          sortField: 'exception',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Статус',
          field: 'exception',
          sortField: 'exception',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Описание',
          field: 'exception',
          sortField: 'exception',
          showInTable: true,
          sort: 0,
        },
      ],
    }
  },

  computed: {
    sidebars() {
      return this.$store.state.sidebars
    },
  },

  mounted() {
    const {id} = this.$route.params
    this.$store.commit('sidebars/changeLeftSidebar', false)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    this.getElements()
  },

  methods: {
    onCreateItem() {
      this.isNew = true
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.$store.commit('events/itemsForDelete', [])
      if (this.$route.name !== 'events') this.$router.push({name: 'events'})
    },
    getElements() {
      EventsService.getEvents()
    },
    async selectItem(data) {
      if (data.column.field !== 'checkbox') await this.$store.commit('sidebars/changeRightSidebar', true)
      await this.$router.push({name: 'events-id', params: {id: data.row.id}})
      this.$refs['event-details'].getData(data.row.id)
    },
    async showRightSidebar() {
      this.$store.commit('events/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', true)
      if (this.$route.name !== 'events') await this.$router.push({name: 'events'})
    },
  },
}
</script>
